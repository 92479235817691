<template>
  <v-card>
    <v-card-title class="align-start">
      Edit Account
    </v-card-title>

    <v-card-text class="text-no-wrap text--primary">
      <v-form ref="form" v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="data.shipper_id"
                label="PB #"
                tabindex="1"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.name"
                :rules="rules.nameRules"
                :counter="50"
                label="Name"
                tabindex="2"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.company"
                label="Company"
                :rules="rules.companyRules"
                tabindex="3"
                :counter="50"
                @keydown.enter="saveUser"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.address"
                label="Address"
                tabindex="4"
                @keydown.enter="saveUser"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.email"
                :rules="rules.emailRules"
                label="E-mail"
                tabindex="5"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="data.phone"
                :rules="[rules.required]"
                label="Phone"
                tabindex="6"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="data.notes"
                label="Notes"
                tabindex="7"
                disabled
                readonly
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-btn color="primary" tabindex="8" @click="saveUser">
              Save
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios'

export default {
  data: () => ({
    valid: false,
    user_id: 0,
    data: {
      shipper_id: '',
      name: '',
      email: '',
      company: '',
      phone: '',
      notes: '',
    },
    rules: {
      required: value => !!value || 'Required.',
      min: v => v.length >= 8 || 'Min 8 characters',
      nameRules: [
        v => !!v || 'Name is required',
        // v => v.length <= 10 || 'Name must be less than 10 characters',
      ],
      companyRules: [
        v => !!v || 'Company is required',
        v => v.length <= 50 || 'Company must be less than 50 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
  }),
  async mounted() {
    const { id } = this.$route.params
    this.user_id = id
    const token = this.$store.state.token
    const url = process.env.VUE_APP_API_URL

    const { data: { user } } = await axios.get(`${url}/api/user/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })

    this.data = {
      shipper_id: user.shipper_id ?? '',
      name: user.name ?? '',
      company: user.company ?? '',
      address: user.address ?? '',
      email: user.email ?? '',
      phone: user.phone ?? '',
      notes: user.notes ?? '',
    }
  },
  methods: {
    async saveUser () {
      let color = 'error'
      let msg = 'Data are still invalid!'

      this.$refs.form.validate()


      try {
        if (this.valid) {
          const token = this.$store.state.token
          const url = process.env.VUE_APP_API_URL

          const { data: { message } } = await axios
            .patch(
              `${url}/api/user/${this.user_id}`,
              this.data,
              { headers: { Authorization: `Bearer ${token}` } }
            )

          color = 'success'
          msg = message

          this.$router.push({ name: 'accounts-show', params: { id: this.user_id } })
        }
      } catch (error) {
        console.log(error);
      }

      this.$root.snackbar.show({ message: msg, color: color });
    },
  },
}
</script>
